import Vue from 'vue';
import Router from 'vue-router';
import { createStore } from '../store';

Vue.use(Router);

// 定义支持的语言列表
const SUPPORTED_LOCALES = ['en', 'zh', 'ja', 'ko', 'vi', 'th', 'hi', 'fa', 'ru', 'de', 'fr', 'ro', 'cs', 'es', 'pt', 'bn', 'it', 'ar', 'ur', 'zh-tw', 'ms', 'tr', 'pl', 'nl', 'uk'];
// 修改 SUPPORTED_LOCALES 的定义方式，添加正则表达式
const LOCALE_REGEX = /^[a-z]{2}(-[a-z]{2})?$/i;  // 匹配 'en', 'zh-tw' 这样的格式
// 通用的 locale 处理函数
const handleLocale = (to, from, next, store) => {
	const locale = to.params.locale;
  // 首先检查是否是静态资源请求
  if (!LOCALE_REGEX.test(locale)) {
    next();  // 不处理静态资源请求
    return;
  }
	if (!SUPPORTED_LOCALES.includes(locale)) {
	  // 如果路径只有语言代码（比如 /ZH），则重定向到根路径
	  if (to.path === `/${locale}`) {
		  next('/');
	  } else {
		  // 否则重定向到英文路径
		  next('/en' + to.path.substr(3));
	  }
	  return;
	}
	// 如果是 /en，重定向到 /
	if (locale === 'en' && to.path === '/en') {
	  next('/');
	  return;
	}
	store.state.urllang = locale;
	next();
};

export default function createRouter(store) {
  const router = new Router({

    // SSR 必须使用 history 模式
    mode: 'history',

    routes: [
      {
	    path: '/',
	    name: 'index',
	    component: () => import('../pages/home.vue')
      },
      {
        path: '/upload',
        name: 'upload',
        component: () => import('../pages/upload.vue')
      },
	    {
        path: '/:locale',
        name: 'home',
        component: () => import('../pages/home.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/tiktok-voice-generator',
        name: 'tiktokvoicegenerator',
        component: () => import('../pages/tiktokvoicegenerator.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/text-to-speech',
        name: 'texttospeech',
        component: () => import('../pages/texttospeech.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/tiktok-ai-voice',
        name: 'tiktokaivoice',
        component: () => import('../pages/tiktokaivoice.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/funny-tts',
        name: 'funnytts',
        component: () => import('../pages/funnytts.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	    {
        path: '/:locale/pricing',
        name: 'pricing',
        component: () => import('../pages/pricing.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	    {
        path: '/:locale/pay/success',
        name: 'pay_success',
        component: () => import('../pages/pay_success.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	  {
        path: '/:locale/pay/cancel',
        name: 'pay_cancel',
        component: () => import('../pages/pay_cancel.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	  {
        path: '/:locale/settings',
        name: 'settings',
        component: () => import('../pages/settings.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	  {
        path: '/:locale/api-intro',
        name: 'api-intro',
        component: () => import('../pages/api_intro.vue'),
		beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/voice-cloning',
        name: 'voice-cloning',
        component: () => import('../pages/voice_cloning.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/sounds-effect',
        name: 'sounds-effect',
        component: () => import('../pages/soundeffect.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	  {
        path: '/:locale/about',
        name: 'about',
        component: () => import('../pages/about.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	  {
		path: '/en/privacy-policy',
		name: 'privacyPolicy',
		component: () => import('../pages/privacy_policy.vue')
	  },
	  {
		path: '/en/terms-of-service',
		name: 'termsService',
		component: () => import('../pages/terms_service.vue')
	  },
      {
        path: '/account/login',
        name: 'user_login',
        component: () => import('../pages/login.vue'),
      },
      {
        path: '/:locale/speechma',
        name: 'speechma',
        component: () => import('../pages/speechma.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/speechma-ai-voice',
        name: 'speechma-ai-voice',
        component: () => import('../pages/speechma_ai_voice.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/hailuo-ai-voice',
        name: 'hailuo-ai-voice',
        component: () => import('../pages/hailuo_ai_voice.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/kokoro-tts',
        name: 'kokoro-tts',
        component: () => import('../pages/kokoro_tts.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/ai-character-voice-generator',
        name: 'ai-character-voice-generator',
        component: () => import('../pages/ai_character_voice_generator.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      // CapCut Voice Over 相关路由
      {
        path: '/:locale/capcut-voice-over',
        name: 'capcut-voiceover',
        component: () => import('../pages/capcut_voice_over.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/capcut-voice-over-alternative',
        name: 'capcut-voiceover-alternative',
        component: () => import('../pages/capcut_voice_over_alternative.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/tiktok-voice-over',
        name: 'tiktok-voiceover',
        component: () => import('../pages/tiktok_voice_over.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
      {
        path: '/:locale/tiktok-voiceover-alternative',
        name: 'tiktok-voiceover-alternative',
        component: () => import('../pages/tiktok_voice_over_alternative.vue'),
        beforeEnter: (to, from, next) => handleLocale(to, from, next, store)
      },
	  // 处理无效路由
	  {
		path: '*',
		redirect: '/'
	  }
    ],
  });

  return router;
};
